import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderBuero from "../../components/header_buero"

const GeschichtePage = ({ data }) => {
  const headerImg = data.allStrapiGeschichte.edges[0].node.picture.localFile.childImageSharp.fluid;
  const headerText = data.allStrapiGeschichte.edges[0].node.content;
  const histories = data.allStrapiHistory.edges;

  return (
    <Layout>
      <SEO title="Geschichte" />
      <HeaderBuero />
      <Img fluid={ headerImg } className="heroimage"/>
      <Reactmarkdown>{ headerText }</Reactmarkdown>

      <div className="grid-mitarbeiter">
          { histories.map(({ node }, index) => (
            <figure key={index}>
              <div>
                <Link to={`/buero/geschichte/${node.slug}`}><Img fluid={ node.pictureprev.localFile.childImageSharp.fluid } style={{height: '150px'}} />
                <div className="grid-desc">{ node.title }<br />
                { node.bueroname }</div>
                </Link>  
              </div>
            </figure>
          ))}   
      </div>

    </Layout>
  )
}

export default GeschichtePage

export const query = graphql`
  query QueryHistory {
    allStrapiGeschichte {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allStrapiHistory {
      edges {
        node {
          slug
          title
          bueroname
          pictureprev {
            localFile {
              childImageSharp {
                fluid(maxHeight: 470) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
